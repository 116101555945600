/* eslint-disable react/no-unstable-nested-components */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import Button from 'components/Button';
import { ReduxStore } from '../../../../types';
import actions from '../actions';
import { Alert } from '../types';

const Notifier: FunctionComponent<{
  alerts: Array<Alert>;
  close: (key: string) => void;
}> = ({ alerts, close }) => {
  const [displayed, setDisplayed] = useState<Array<string>>([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const removeDisplayed = (key: string) => {
    setDisplayed(displayed.filter((k) => k !== key));
  };
  useEffect(() => {
    if (alerts?.length) {
      const d: string[] = [];
      for (let i = 0; i < alerts?.length || 0; i += 1) {
        const { key, message, variant, dismissed = false } = alerts[i];
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
        }
        // do nothing if snackbar is already displayed
        else if (!displayed.includes(key)) {
          // display snackbar using notistack
          enqueueSnackbar(message, {
            persist:
              alerts[i]?.variant === 'error' ||
              alerts[i]?.variant === 'warning',
            preventDuplicate: true,
            key,
            variant,
            anchorOrigin: {
              horizontal: 'left',
              vertical: 'bottom',
            },

            action: (k: string) => (
              <Button
                small
                icon="close"
                white
                onClick={() => closeSnackbar(k)}
              />
            ),

            onExited: (event, k) => {
              // remove this snackbar from redux store
              if (!k) return;
              const k2 = typeof k === 'string' ? k : k?.toString();
              close(k2);
              removeDisplayed(k2);
            },
          });
          d.push(key);
        }
      }
      // keep track of snackbars that we've displayed
      if (d.length > 0) {
        setDisplayed([...displayed, ...d]);
      }
    }
  }, [alerts, closeSnackbar, enqueueSnackbar]);

  return null;
};

const ms = ({ notification }: ReduxStore) => ({
  alerts: notification?.alert?.alerts || [],
});

const md = {
  close: actions.close,
};

export default connect(ms, md)(Notifier);
