
    var refs = 0;
    var css = require("!!../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].rules[2]!../../../../.yarn/__virtual__/postcss-loader-virtual-efe46bb6b5/0/cache/postcss-loader-npm-6.2.1-45828eb0de-e40ae79c3e.zip/node_modules/postcss-loader/dist/cjs.js!../../../../.yarn/__virtual__/less-loader-virtual-e54b7d100c/0/cache/less-loader-npm-10.2.0-9c6619a5c0-42a2b56a9d.zip/node_modules/less-loader/dist/cjs.js!./VerificationCode.less");
    var insertCss = require("!../../../../.yarn/__virtual__/isomorphic-style-loader-virtual-0e62a9079e/0/cache/isomorphic-style-loader-npm-5.3.2-48b43c30d2-3f8f3d847b.zip/node_modules/isomorphic-style-loader/insertCss.js");
    var content = typeof css === 'string' ? [[module.id, css, '']] : css;

    exports = module.exports = css.locals || {};
    exports._getContent = function() { return content; };
    exports._getCss = function() { return '' + css; };
    exports._insertCss = function(options) { return insertCss(content, options) };

    // Hot Module Replacement
    // https://webpack.github.io/docs/hot-module-replacement
    // Only activated in browser context
    if (module.hot && typeof window !== 'undefined' && window.document) {
      var removeCss = function() {};
      module.hot.accept("!!../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].rules[2]!../../../../.yarn/__virtual__/postcss-loader-virtual-efe46bb6b5/0/cache/postcss-loader-npm-6.2.1-45828eb0de-e40ae79c3e.zip/node_modules/postcss-loader/dist/cjs.js!../../../../.yarn/__virtual__/less-loader-virtual-e54b7d100c/0/cache/less-loader-npm-10.2.0-9c6619a5c0-42a2b56a9d.zip/node_modules/less-loader/dist/cjs.js!./VerificationCode.less", function() {
        css = require("!!../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].rules[2]!../../../../.yarn/__virtual__/postcss-loader-virtual-efe46bb6b5/0/cache/postcss-loader-npm-6.2.1-45828eb0de-e40ae79c3e.zip/node_modules/postcss-loader/dist/cjs.js!../../../../.yarn/__virtual__/less-loader-virtual-e54b7d100c/0/cache/less-loader-npm-10.2.0-9c6619a5c0-42a2b56a9d.zip/node_modules/less-loader/dist/cjs.js!./VerificationCode.less");
        content = typeof css === 'string' ? [[module.id, css, '']] : css;
        removeCss = insertCss(content, { replace: true });
      });
      module.hot.dispose(function() { removeCss(); });
    }
  