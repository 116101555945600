import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import { AppThunkDispatch, ReduxStore } from 'src/customer/types';
import { useInterval } from 'src/utils/hooks';
import Button from 'components/Button/Button';
import VerificationCode from 'components/Form/VerificationCode/VerificationCode';
import EmailIcon from 'components/Icons/EmailIcon/EmailIcon';
import Link from 'components/Link/Link';
import loginActions from '../Login/actions';
import { AuthContext } from '../Login/types';
import actions from './actions';
import service from './service';
import s from './Confirm.less';

export class AuthenticationFailure extends Error {
  constructor() {
    super();
    this.name = this.constructor.name;
    this.message = 'UnauthenticatedError';
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, AuthenticationFailure.prototype);
  }
}

export type ConfirmFailure = AuthenticationFailure | Error;

/**
 * decodes confirmation token which has been encoded using Base64 encoding for
 * the URL to string of six decimals.
 * @param confirmToken the Base64 encoded token
 * @returns string of 6 decimal numbers
 */
export const getCode = (confirmToken?: string) => {
  try {
    if (
      confirmToken &&
      typeof confirmToken === 'string' &&
      confirmToken.length > 0 &&
      typeof atob === 'function'
    ) {
      return atob(confirmToken);
    }
  } catch (error) {
    /** do nothing */
  }
  return '';
};

function getColor(isVerified: boolean, isFailure: boolean) {
  if (isFailure) return 'danger';
  if (isVerified) return 'success';
  return 'default';
}

interface Props {
  onSuccess?: (a: AuthContext) => void;
  onFailure?: (error: ConfirmFailure) => void;
  confirmToken?: string;
  redirectUri?: string;
  partnerId?: string;
}

const ConfirmComponent = ({
  onSuccess,
  onFailure,
  confirmToken,
  redirectUri,
  partnerId,
}: Props) => {
  useStyles(s);

  const dispatch = useDispatch<AppThunkDispatch>();
  const { authContext, isConfirmRequest, isConfirmFailure } = useSelector(
    ({ iam }: ReduxStore) => ({
      authContext: iam?.login?.auth,
      isConfirmRequest: !!iam?.confirm?.isConfirmRequest,
      isConfirmFailure: iam?.confirm?.isConfirmFailure,
    }),
  );

  const [isResendRequest, setIsResendRequest] = useState(false);
  const [resendDelay, setResendDelay] = useState<number>();
  const [resentLast, setResentLast] = useState(0);
  const [resentLastDiff, setResentLastDiff] = useState('');
  const [isFailure, setIsFailure] = useState(false);
  const isVerified = !!authContext?.auth?.verified;

  const handleSuccess = async () => {
    if (onSuccess) {
      // we need to refresh the authContext in case of the race condition that
      // login and confirm requests ran at the same time and the login request
      // finished later with verified=false.
      if (!authContext?.auth?.verified) {
        const ctx = await dispatch(loginActions.authContext());
        if (ctx?.auth?.verified) {
          onSuccess(ctx);
        } else {
          setIsFailure(true);
          const error = new Error(
            'auth context not verified even after being confirmed.',
          );
          console.error(error, ctx);
          if (onFailure && error instanceof Error) onFailure(error);
        }
      } else {
        onSuccess(authContext);
      }
    }
  };
  const handleResendTimer = useCallback(
    (min) => {
      if (min > 0) {
        setResentLastDiff(`You can try again in ${min}min`);
        setResentLast(min - 1);
        if (!resendDelay) setResendDelay(60000);
      } else {
        setResentLastDiff('');
        setResentLast(0);
        setResendDelay(undefined);
      }
    },
    [setResentLastDiff, setResentLast, setResendDelay],
  );

  const handleResend = useCallback(() => {
    if (authContext?.email) {
      setIsResendRequest(true);
      setIsFailure(false);
      service
        .resend({
          email: authContext.email,
          redirectUri,
          partner: partnerId,
        })
        .then(() => {
          handleResendTimer(10);
        })
        .catch((e) => {
          // parse the minutes from the message. This is brittle and we should return
          // the minutes in the response as a property.
          if (e?.code === 400 && e?.message) {
            const match = e?.message.match(/\d+/);
            if (Array.isArray(match)) {
              const min = parseInt(e?.message.match(/\d+/)[0], 10);
              handleResendTimer(min);
              return;
            }
          }
          console.error(e);
          setIsFailure(true);
          if (onFailure && e instanceof Error) onFailure(e);
        })
        .finally(() => {
          setIsResendRequest(false);
        });
    }
  }, [setIsResendRequest, setIsFailure, service, handleResendTimer]);

  const handleConfirm = async (code: string) => {
    if (isFailure) setIsFailure(false);
    if (!isConfirmRequest && code?.length === 6) {
      try {
        await dispatch(actions.confirm({ token: btoa(code) }));
      } catch (error) {
        console.error(error);
        setIsFailure(true);
        if (onFailure && error instanceof Error) onFailure(error);
      }
    }
  };

  useInterval(() => {
    handleResendTimer(resentLast);
  }, resendDelay);

  // Poll auth context in case it's been updated in another device
  // e.g. when the user clicks the link the email notification.
  useInterval(
    () => {
      if (!isVerified && authContext && !confirmToken)
        dispatch(loginActions.authContext()).catch((error) => {
          console.error(error);
          setIsFailure(true);
        });
    },
    isVerified || !authContext || confirmToken ? undefined : 3000,
  );

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isVerified && typeof onSuccess === 'function') {
      timeout = setTimeout(() => {
        handleSuccess();
      }, 5000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isVerified]);

  useEffect(() => {
    setIsFailure(!!isConfirmFailure);
  }, [isConfirmFailure]);

  useEffect(() => {
    if (confirmToken && !isConfirmRequest) {
      handleConfirm(getCode(confirmToken));
    }
  }, [confirmToken]);
  return (
    <div className={s.root}>
      <div className={s.icon}>
        <EmailIcon color={getColor(isVerified, isFailure)} />
      </div>
      {!isVerified && (
        <>
          <h5 className={s.title}>Enter the verification code</h5>
          <VerificationCode
            style={{ marginBottom: '15px' }}
            onChange={handleConfirm}
            isFailure={isFailure}
            defaultValue={getCode(confirmToken)}
          />
          <p>
            <small>
              Let&apos;s make sure that you entered the correct email.
              We&apos;ve just sent you an email with a fresh verification code
              to your email <b>{authContext?.email}</b>. Enter the code to
              continue.
            </small>
          </p>
        </>
      )}
      {isVerified && (
        <div>
          <h5 className={s.title}>Your email has been verified!</h5>
          <small>
            {typeof onSuccess === 'function' && (
              <p>
                <Button
                  style={{
                    display: 'inline',
                    width: 'auto',
                    marginRight: '10px',
                  }}
                  medium
                  success
                  onClick={handleSuccess}
                >
                  Continue
                </Button>{' '}
                or wait for a few seconds.
              </p>
            )}
            {typeof onSuccess !== 'function' && (
              <p>You can close this window.</p>
            )}
          </small>
        </div>
      )}

      {!isVerified && (
        <>
          {!!isFailure && (
            <>
              <p className={s.failure}>
                <small>
                  <b>Sorry!</b> Something went wrong when verifying your email.
                  <br />
                  Please make sure that you opened the most recent email from
                  us.
                  <br />
                  It could also be that the code has expired.
                </small>
              </p>
              <p>
                <small />
              </p>
            </>
          )}

          <p>
            <small>
              Didn&apos;t receive a code or something not right?
              <br />
              <Button
                style={{
                  display: 'inline',
                  width: 'auto',
                  marginRight: '5px',
                }}
                medium
                onClick={handleResend}
                disabled={isResendRequest || !!resentLastDiff}
                success={!!resentLastDiff}
              >
                {resentLastDiff || 'Try again'}
              </Button>{' '}
              or <Link to="/help">contact support</Link>.
            </small>
          </p>
        </>
      )}
    </div>
  );
};

export default ConfirmComponent;
