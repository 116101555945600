import React, { FunctionComponent, HTMLAttributes } from 'react';
import history from 'src/history';

function isLeftClickEvent(event: React.MouseEvent<HTMLElement>) {
  return event.button === 0;
}

function isModifiedEvent(event: React.MouseEvent<HTMLElement>) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

const Link: FunctionComponent<
  HTMLAttributes<HTMLAnchorElement> & {
    to?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>, to?: string) => void;
  }
> = ({ to, children, onClick, ...other }) => {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented === true) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    if (onClick) onClick(event, to);
    if (history && to) {
      history.push(to, {
        previous: `${history?.location?.pathname}${history?.location?.search}`,
      });
    }
  };

  return (
    <a href={to} {...other} onClick={handleClick}>
      {children}
    </a>
  );
};

export default Link;
