// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DiVAY{margin:15px 0}.DiVAY,.DiVAY .V1xeX{display:-ms-flexbox;display:flex;position:relative}.DiVAY .V1xeX{-ms-flex-align:center;-ms-flex-pack:center;align-items:center;background:#fff;border:1px solid var(--border-color);border-radius:var(--border-radius);-webkit-box-shadow:var(--box-shadow);box-shadow:var(--box-shadow);font-size:32px;height:60px;justify-content:center;margin-right:5px;width:46px}.DiVAY .V1xeX:last-of-type{margin-right:0}.DiVAY .w2DIg{background-color:transparent;border:none;font-size:32px;opacity:1;outline:none;text-align:center;width:46px}.DiVAY .w2DIg,.DiVAY .usCZC{bottom:0;position:absolute;top:0}.DiVAY .usCZC{border-radius:var(--border-radius);-webkit-box-shadow:0 0 0 2px var(--brand-color-blue);box-shadow:0 0 0 2px var(--brand-color-blue);left:0;right:0}.w2DIg.TVMFb{opacity:0}.cudp6{left:0}.FX4fw{left:51px}.D5clS{left:102px}.u1A3p{left:153px}.TuV4f{left:204px}.mH7HD{left:255px}@media (max-width:574px){.DiVAY{margin:15px 0}.DiVAY .V1xeX{height:42px;margin-right:5px}.DiVAY .V1xeX,.DiVAY .w2DIg{font-size:16px;width:32px}.DiVAY .cudp6{left:0}.DiVAY .FX4fw{left:37px}.DiVAY .D5clS{left:74px}.DiVAY .u1A3p{left:111px}.DiVAY .TuV4f{left:148px}.DiVAY .mH7HD{left:185px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "DiVAY",
	"display": "V1xeX",
	"input": "w2DIg",
	"shadow": "usCZC",
	"hideInput": "TVMFb",
	"offset0": "cudp6",
	"offset1": "FX4fw",
	"offset2": "D5clS",
	"offset3": "u1A3p",
	"offset4": "TuV4f",
	"offset5": "mH7HD"
};
module.exports = ___CSS_LOADER_EXPORT___;
