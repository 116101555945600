import ValidationError from 'src/utils/error';
import { resetValidationErrors } from 'src/utils/validation';
import { ValidationErrors } from 'types/validationTypes';
import { AppThunk } from '../../../types';
import { alertFailure } from '../../notification/Alert/actions';
import {
  CREATE_VERSION_FAILURE,
  CREATE_VERSION_REQUEST,
  CREATE_VERSION_SUCCESS,
  READ_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  STORE_FAILURE,
  STORE_REQUEST,
  STORE_SUCCESS,
} from './constants';
import service from './service';
import { User } from './types';
import validateUser from './validation';

const read = (): AppThunk<Promise<User | void>> => {
  const request = () => ({ type: READ_REQUEST });

  const success = (item: User) => ({
    type: READ_SUCCESS,
    item,
  });

  const failure = (error?: ValidationErrors) => ({
    type: READ_FAILURE,
    error: error || null,
  });

  return async (dispatch) => {
    dispatch(request());
    try {
      const item = await service.read();
      dispatch(success(item));
      return item;
    } catch (error) {
      if (error) dispatch(alertFailure(<ValidationError>error, failure));
      return undefined;
    }
  };
};

const store = (data: Partial<User>): AppThunk<Promise<User | void>> => {
  const request = (error?: ValidationErrors) => ({
    type: STORE_REQUEST,
    item: data,
    error,
  });
  const success = ({ item }: { item: User }) => ({
    type: STORE_SUCCESS,
    item,
  });
  const failure = (error?: ValidationErrors) => ({
    type: STORE_FAILURE,
    error,
  });
  return async (dispatch, getState) => {
    // todo:  for now we do the validation in the front end
    //        The validation will be moved to the back end once
    //        we got some confidence and experience with these values.
    //        This is to heavy also. We need to handle the validation in steps.
    const s = getState();
    const resetErrors = resetValidationErrors(
      s?.kyc?.user?.validationErrors,
      data,
    );
    const keys = Object.keys(data) || [];
    const dataErrors = ValidationError.parseErrors(
      validateUser(data).errors,
    ).filterErrors(keys);
    const ve = {
      ...resetErrors,
      ...dataErrors,
    };
    dispatch(request(ve));
    try {
      const response = await service.store(data);
      dispatch(success({ item: response }));
      return response;
    } catch (error) {
      if (error instanceof ValidationError) {
        dispatch(
          alertFailure(
            ValidationError.parseErrors({
              ...ve,
              ...error.filterErrors(Object.keys(data) || []),
            }),
            failure,
          ),
        );
      }
      throw error;
    }
  };
};

const createVersion = (): AppThunk<Promise<User | void>> => {
  const request = () => ({
    type: CREATE_VERSION_REQUEST,
  });
  const success = (item: User) => ({
    type: CREATE_VERSION_SUCCESS,
    item,
  });
  const failure = (error?: ValidationErrors) => ({
    type: CREATE_VERSION_FAILURE,
    error: error || null,
  });

  return async (dispatch) => {
    dispatch(request());

    const user = await service.read();
    const userValid = validateUser(user);
    if (!userValid.isValid) {
      dispatch(
        alertFailure(ValidationError.parseErrors(userValid.errors), failure),
      );
      return undefined;
    }
    // check if there is a version and if it has been changed
    if (
      user?.meta?.createdAt &&
      user?.meta?.createdAt === user?.meta?.updatedAt
    ) {
      // no change
      dispatch(success(user));
      return user;
    }
    return service
      .createVersion()
      .then((version) => {
        dispatch(success(version));
        return version;
      })
      .catch((error) => {
        dispatch(alertFailure(error, failure));
        return undefined;
      });
  };
};
export default {
  read,
  store,
  createVersion,
};
