import React, { FC, HTMLAttributes } from 'react';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
// eslint-disable-next-line css-modules/no-unused-class
import s from './Stripe.less';

const Stripe: FC<
  {
    gradient?: 'teal' | 'pink' | 'tomato' | 'lime';
  } & HTMLAttributes<HTMLDivElement>
> = ({ gradient = 'teal', ...other }) => (
  <div className={cx(s.root, s[gradient])} {...other} />
);

export default withStyles(s)(Stripe);
