// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I6uAt{height:4px;margin-bottom:30px;-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;width:auto}.shqmp{background:-o-linear-gradient(359deg,#ff1493 0,coral 100%);background:linear-gradient(91deg,#ff1493,coral)}.QIHfz{background:-o-linear-gradient(359deg,#6a5acd 0,#00bfff 100%);background:linear-gradient(91deg,#6a5acd,#00bfff)}.ZxgC9{background:-o-linear-gradient(359deg,tomato 0,gold 100%);background:linear-gradient(91deg,tomato,gold)}.hfbI1{background:-o-linear-gradient(359deg,#54bc60 0,#d5e056 100%);background:linear-gradient(91deg,#54bc60,#d5e056)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "I6uAt",
	"pink": "shqmp",
	"teal": "QIHfz",
	"tomato": "ZxgC9",
	"lime": "hfbI1"
};
module.exports = ___CSS_LOADER_EXPORT___;
