import React, { FC, LabelHTMLAttributes } from 'react';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Label.less';

const Label: FC<
  LabelHTMLAttributes<HTMLLabelElement> & {
    hasFocus?: boolean;
    hasValue?: boolean;
    relative?: boolean;
  }
> = ({
  hasFocus = false,
  hasValue = false,
  relative = false,
  children,
  ...other
}) => {
  return (
    <label
      className={cx(s.label, {
        [s.hasFocus]: hasFocus,
        [s.hasValue]: hasValue,
        [s.relative]: relative,
      })}
      {...other}
    >
      {children}
    </label>
  );
};

export default withStyles(s)(Label);
