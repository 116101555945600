// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fgbWR{color:#a6afb4;display:block;font-size:16px;font-size:1rem;font-weight:300;left:10px;line-height:16px;line-height:1rem;position:absolute;top:18px;-webkit-transition:all .2s ease;-o-transition:all .2s ease;transition:all .2s ease}.fgbWR>input,.fgbWR>span{vertical-align:middle}.fgbWR>input{margin-right:15px}.formError .fgbWR{color:var(--danger-border-color)}.LoHPI,.F2GFY,input:focus~.fgbWR,textarea:focus~.fgbWR{font-size:12px;font-size:.75rem;font-weight:300;line-height:12px;line-height:.75rem;top:8px}.O_9xh{left:0;line-height:inherit;margin-bottom:5px;position:relative;top:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "fgbWR",
	"hasFocus": "LoHPI",
	"hasValue": "F2GFY",
	"relative": "O_9xh"
};
module.exports = ___CSS_LOADER_EXPORT___;
