// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BcMAh{-webkit-appearance:none;-moz-appearance:none;appearance:none;background:#fff;border:1px solid var(--input-border-color);border-radius:2px;-webkit-box-shadow:none;box-shadow:none;-webkit-box-sizing:border-box;box-sizing:border-box;color:var(--brand-color-darkgray);display:block;font-size:1em;font-weight:300;min-height:50px;outline:none;padding:24px 10px 5px;-webkit-transition:all .3s ease;-o-transition:all .3s ease;transition:all .3s ease;width:100%}.formError .BcMAh{border-color:var(--danger-border-color)}.BcMAh:focus{border:1px solid var(--input-border-focus-color);-webkit-box-shadow:none;box-shadow:none;outline:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "BcMAh"
};
module.exports = ___CSS_LOADER_EXPORT___;
