// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VFH7o{-ms-flex-pack:end;-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;justify-content:flex-end;width:100%}.F3qRY>button{background-color:var(--warning-background-color);border-color:#fff!important;height:24px;margin-right:12px;width:24px}.F3qRY>button i{color:#fff!important;font-size:16px!important;font-size:1rem!important}.F3qRY>button:hover{background-color:#fff;border-color:var(--warning-background-color)!important}.F3qRY>button:hover i{color:var(--warning-background-color)!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "VFH7o",
	"linkBtnWrapper": "F3qRY"
};
module.exports = ___CSS_LOADER_EXPORT___;
