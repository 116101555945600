import { AppThunk } from 'src/customer/store/configureStore';
import { ValidationErrors } from 'src/types/validationTypes';
import ValidationError from 'src/utils/error';
import alertActions from '../../notification/Alert/actions';
import { AuthContext } from '../Login/types';
import {
  CONFIRM_FAILURE,
  CONFIRM_REQUEST,
  CONFIRM_SUCCESS,
  RESEND_FAILURE,
  RESEND_REQUEST,
  RESEND_SUCCESS,
} from './constants';
import service from './service';
import { ConfirmRequest, ResendRequest, ResendResponse } from './types';

const confirm = (param: ConfirmRequest): AppThunk<Promise<AuthContext>> => {
  const request = () => ({ type: CONFIRM_REQUEST });
  const success = (item: AuthContext) => ({ type: CONFIRM_SUCCESS, item });
  const failure = (error?: ValidationErrors) => ({
    type: CONFIRM_FAILURE,
    error,
  });

  return async (dispatch) => {
    dispatch(request());
    try {
      const ctx = await service.confirm(param);
      dispatch(success(ctx));
      return ctx;
    } catch (err) {
      if (err instanceof ValidationError) dispatch(failure(err.errors));
      throw err;
    }
  };
};

const resend = (
  data: ResendRequest,
): AppThunk<Promise<ResendResponse | void>> => {
  const request = () => ({ type: RESEND_REQUEST });
  const success = () => ({ type: RESEND_SUCCESS });
  const failure = (error: ValidationErrors) => ({
    type: RESEND_FAILURE,
    error,
  });

  return (dispatch) => {
    dispatch(request());
    return service
      .resend(data)
      .then((r) => {
        dispatch(success());
        dispatch(alertActions.success('verification email has been sent'));
        return r;
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
};

export default {
  confirm,
  resend,
};
