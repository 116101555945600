import React, { forwardRef, InputHTMLAttributes } from 'react';
import cx from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './InputField.less';

const InputField = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>(({ ...other }, ref) => {
  useStyles(s);
  return (
    <input
      ref={ref}
      {...other}
      className={cx(s.input, other?.className || '')}
    />
  );
});

export default InputField;
