/**
 * List of allowed nationalities from Monerium's Anti-Money Laundering
 * and Countering Financing of Terrorism Management Procedure document
 * version 5.10
 */
const allowedNationalities: string[] = [
  'AX', // label: 'Åland Islands', en: 'Åland Islands',
  'AD', // label: 'Andorra', en: 'Andorra',
  'AR', // label: 'Argentina', en: 'Argentina',
  'AU', // label: 'Australia', en: 'Australia',
  'AT', // label: 'Austria (Österreich)', en: 'Austria',
  'BE', // label: 'Belgium (België)', en: 'Belgium',
  'BR', // label: 'Brazil (Brasil)', en: 'Brazil',
  'BG', // label: 'Bulgaria (България)', en: 'Bulgaria',
  'CA', // label: 'Canada', en: 'Canada',
  'CL', // label: 'Chile', en: 'Chile',
  'CO', // label: 'Colombia', en: 'Colombia',
  'HR', // label: 'Croatia (Hrvatska)', en: 'Croatia',
  'CY', // label: 'Cyprus (Κύπρος)', en: 'Cyprus',
  'CZ', // label: 'Czech Republic (Česká republika)', en: 'Czech Republic',
  'DK', // label: 'Denmark (Danmark)', en: 'Denmark',
  'EC', // label: 'Ecuador', en: 'Ecuador',
  'EE', // label: 'Estonia (Eesti)', en: 'Estonia',
  'FO', // label: 'Faroe Islands (Føroyar)', en: 'Faroe Islands',
  'FI', // label: 'Finland (Suomi)', en: 'Finland',
  'FR', // label: 'France (République française)', en: 'France',
  'DE', // label: 'Germany (Deutschland)', en: 'Germany',
  'GR', // label: 'Greece (Ελλάδα)', en: 'Greece',
  'GP', // label: 'Guadeloupe', en: 'Guadeloupe',
  'HU', // label: 'Hungary (Magyarország)', en: 'Hungary',
  'IS', // label: 'Iceland (Ísland)', en: 'Iceland',
  'IN', // label: 'India (भारत)', en: 'India',
  'IE', // label: 'Ireland', en: 'Ireland',
  'IL', // label: 'Israel (‫ישראל‬‎)', en: 'Israel',
  'IT', // label: 'Italy (Italia)', en: 'Italy',
  'JP', // label: 'Japan (日本)', en: 'Japan',
  'LV', // label: 'Latvia (Latvija)', en: 'Latvia',
  'LI', // label: 'Liechtenstein', en: 'Liechtenstein',
  'LT', // label: 'Lithuania (Lietuva)', en: 'Lithuania',
  'LU', // label: 'Luxembourg (Lëtzebuerg)', en: 'Luxembourg',
  'MK', // label: 'Macedonia (FYROM) (Македонија)', en: 'Macedonia (FYROM)',
  'MT', // label: 'Malta', en: 'Malta',
  'MX', // label: 'Mexico (México)', en: 'Mexico',
  'NL', // label: 'Netherlands (Nederland)', en: 'Netherlands',
  'NZ', // label: 'New Zealand', en: 'New Zealand',
  'NO', // label: 'Norway (Norge)', en: 'Norway',
  'PY', // label: 'Paraguay (Tetã Paraguái)', en: 'Paraguay',
  'PE', // label: 'Peru (Perú)', en: 'Peru',
  'PL', // label: 'Poland (Polska)', en: 'Poland',
  'PT', // label: 'Portugal (República Portuguesa)', en: 'Portugal',
  'RO', // label: 'Romania (România)', en: 'Romania',
  'RS', // label: 'Serbia (Србија)', en: 'Serbia',
  'SG', // label: 'Singapore (Singapura) (新加坡共和国)', en: 'Singapore (Singapura)',
  'SK', // label: 'Slovakia (Slovensko)', en: 'Slovakia',
  'SI', // label: 'Slovenia (Slovenija)', en: 'Slovenia',
  'KR', // label: 'South Korea (대한민국)', en: 'South Korea',
  'ES', // label: 'Spain (España)', en: 'Spain',
  'SE', // label: 'Sweden (Sverige)', en: 'Sweden',
  'CH', // label: 'Switzerland (Schweiz)', en: 'Switzerland',
  'TW', // label: 'Taiwan (台灣)', en: 'Taiwan',
  'TH', // label: 'Thailand (ไทย)', en: 'Thailand',
  'TN', // label: 'Tunisia (‫تونس‬‎)', en: 'Tunisia',
  'GB', // label: 'United Kingdom', en: 'United Kingdom',
  'UA', // label: 'Ukraine (Україна)', en: 'Ukraine',
  'UY', // label: 'Uruguay', en: 'Uruguay',
];

export default allowedNationalities;
