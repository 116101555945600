import { isFalsy, isTruthy, isYesOrNo, Validator } from 'src/utils/validation';
import { CorporateProfile, PersonalProfile, Profile } from './types';
import allowedCountries from './Wizard/Corporation/Form/Jurisdiction/allowedCountries';

export const validateKind = (
  profile?: Partial<Profile>,
  valid = new Validator(),
): Validator => {
  if (!profile?.kind)
    valid.addError(
      'kind',
      'Please select what kind of profile you would like to create.',
    );
  return valid;
};

export const validateFinance = (
  profile?: Partial<PersonalProfile>,
  valid = new Validator(),
): Validator => {
  if (!profile?.occupation)
    valid.addError('occupation', 'This field is required');
  if (!profile?.profession)
    valid.addError('profession', 'This field is required');
  if (!profile?.annualIncome)
    valid.addError('annualIncome', 'This field is required');
  if (!profile?.fundOrigins)
    valid.addError('fundOrigins', 'This field is required');
  if (!profile?.monthlyTurnover)
    valid.addError('monthlyTurnover', 'This field is required');
  if (!profile?.monthlyTransactionCount)
    valid.addError('monthlyTransactionCount', 'This field is required');
  return valid;
};

export const validateActivity = (
  profile?: Partial<PersonalProfile>,
  valid = new Validator(),
): Validator => {
  if (
    isFalsy(profile?.activityArbitrage) &&
    isFalsy(profile?.activityInvesting) &&
    isFalsy(profile?.activityTrading) &&
    isFalsy(profile?.activityOnlineGambling) &&
    isFalsy(profile?.activityCommerce) &&
    isFalsy(profile?.activityCommerceSelling) &&
    isFalsy(profile?.activityCommerceBuying) &&
    isFalsy(profile?.activityInvestingCrypto) &&
    !profile?.activityOther?.trim().length
  )
    valid.addError(
      'activity',
      'Please select or specify at least one activity ',
    );

  if (
    profile?.activityCommerceSelling &&
    !profile?.commerceSellingClarification
  ) {
    valid.addError(
      'commerceSellingClarification',
      'Please provide information about the goods and services you provide',
    );
  }

  if (
    profile?.activityCommerceSelling &&
    profile?.commerceSellingClarification &&
    profile?.commerceSellingClarification.length > 500
  ) {
    valid.addError(
      'commerceSellingClarification',
      'Maximum length is 500 characters',
    );
  }

  return valid;
};

export const validateAffiliations = (
  profile?: Partial<PersonalProfile>,
  valid = new Validator(),
): Validator => {
  if (!isYesOrNo(profile?.ownerOfFunds))
    valid.addError('ownerOfFunds', 'Please select either yes or no');

  if (!isYesOrNo(profile?.publicFunction))
    valid.addError('publicFunction', 'Please select either yes or no');

  if (isFalsy(profile?.ownerOfFunds) && !profile?.ownerOfFundsClarification) {
    valid.addError(
      'ownerOfFundsClarification',
      'Please provide information about the owner of the funds',
    );
  }

  if (
    isFalsy(profile?.ownerOfFunds) &&
    profile?.ownerOfFundsClarification &&
    profile?.ownerOfFundsClarification.length > 500
  ) {
    valid.addError(
      'ownerOfFundsClarification',
      'Maximum length is 500 characters',
    );
  }

  if (
    isTruthy(profile?.publicFunction) &&
    !profile?.publicFunctionClarification
  ) {
    valid.addError(
      'publicFunctionClarification',
      'Please provide the required information',
    );
  }

  if (
    isTruthy(profile?.publicFunction) &&
    profile?.publicFunctionClarification &&
    profile?.publicFunctionClarification.length > 500
  ) {
    valid.addError(
      'publicFunctionClarification',
      'Maximum length is 500 characters',
    );
  }

  return valid;
};

export const validateIncorporation = (
  profile?: Partial<CorporateProfile>,
  valid = new Validator(),
): Validator => {
  if (!profile?.companyName)
    valid.addError('companyName', 'This field is required');
  if (!profile?.registrationNumber)
    valid.addError('registrationNumber', 'This field is required');
  if (!profile?.registrationDate)
    valid.addError('registrationDate', 'This field is required');
  if (!profile?.vatNumber)
    valid.addError('vatNumber', 'This field is required');
  if (!profile?.legalForm)
    valid.addError('legalForm', 'This field is required');
  if (!profile?.website) valid.addError('website', 'This field is required');
  return valid;
};

export const validateJurisdiction = (
  profile?: Partial<CorporateProfile>,
  valid = new Validator(),
): Validator => {
  if (!profile?.country) valid.addError('country', 'This field is required');
  else if (
    !allowedCountries.find(
      (x) => x.toLowerCase() === String(profile?.country).toLowerCase(),
    )
  )
    valid.addError(
      'country',
      'Only European countries are allowed at the moment',
    );
  if (!profile?.postalCode)
    valid.addError('postalCode', 'This field is required');
  if (!profile?.city) valid.addError('city', 'This field is required');
  if (!profile?.address) valid.addError('address', 'This field is required');
  return valid;
};

export const validateContact = (
  profile?: Partial<CorporateProfile>,
  valid = new Validator(),
): Validator => {
  // a VERY simple email validation
  const regEx = /\S+@\S+\.\S+/;
  if (!profile?.email) {
    valid.addError('email', 'This field is required');
  } else if (!regEx.test(profile?.email)) {
    valid.addError(
      'email',
      "Email format is not valid (e.g. 'user@domain.com')",
    );
  }
  if (!profile?.phone) valid.addError('phone', 'This field is required');
  return valid;
};

export const validateNature = (
  profile?: Partial<CorporateProfile>,
  valid = new Validator(),
): Validator => {
  if (!profile?.activity) valid.addError('activity', 'This field is required');
  if (!profile?.purpose) valid.addError('purpose', 'This field is required');
  if (!profile?.fundOrigins)
    valid.addError('fundOrigins', 'This field is required');
  if (!profile?.monthlyTransactionCount)
    valid.addError('monthlyTransactionCount', 'This field is required');
  if (!profile?.monthlyTurnover)
    valid.addError('monthlyTurnover', 'This field is required');
  if (!profile?.service) valid.addError('service', 'This field is required');
  if (profile?.service && profile.service.length > 5000)
    valid.addError('service', 'Maximum length is 5000 characters');
  return valid;
};

export const validateBusinessTerms = (
  profile?: Partial<CorporateProfile>,
  valid = new Validator(),
): Validator => {
  const isCorporate = profile?.kind === 'corporate';
  if (isFalsy(profile?.businessToS) && isCorporate)
    valid.addError('businessTerms', 'Please accept the terms');
  return valid;
};

export const validateRole = (
  profile?: Partial<CorporateProfile>,
  valid = new Validator(),
): Validator => {
  const finalBeneficiary = profile?.finalBeneficiaries?.find(
    (fb) => fb.id === profile?.user?.id,
  );

  const ownershipPercentage = finalBeneficiary?.ownershipPercentage;

  if (finalBeneficiary && !ownershipPercentage)
    valid.addError('role', 'Please fill in the ownership percentage');
  if (finalBeneficiary && Number(ownershipPercentage) <= 0)
    valid.addError('role', 'Ownership percentage must be more than 0');
  if (finalBeneficiary && Number(ownershipPercentage) > 100)
    valid.addError('role', 'Ownership percentage can not exceed 100');

  return valid;
};

export const validateDirectors = (
  profile?: Partial<CorporateProfile>,
  valid = new Validator(),
): Validator => {
  if (!profile?.directors || profile?.directors.length === 0)
    valid.addError(
      'director',
      'Please add information about the entities directors or those with power of procuration',
    );

  return valid;
};

export const validatePersonal = (
  profile?: Partial<Profile>,
  valid = new Validator(),
): Validator => {
  validateFinance(profile, valid);
  validateActivity(profile, valid);
  validateAffiliations(profile, valid);
  return valid;
};

export const validateCorporate = (
  profile?: Partial<Profile>,
  valid = new Validator(),
): Validator => {
  validateIncorporation(profile, valid);
  validateJurisdiction(profile, valid);
  validateContact(profile, valid);
  validateNature(profile, valid);
  return valid;
};

export default (profile?: Profile, valid = new Validator()): Validator => {
  if (!profile?.id) valid.addError('id', 'Profile not found ');
  if (profile?.kind === 'personal') {
    validatePersonal(<Profile>profile, valid);
  } else if (profile?.kind === 'corporate') {
    validateCorporate(profile, valid);
  }
  return valid;
};
