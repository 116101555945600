import React, { useEffect, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount as useWagmiAccount } from 'wagmi';
import Button from 'src/components/Button';
import { equal } from 'src/utils/address';
import useAccount from '../Account/hooks';
import { useWallet } from './WalletProvider';
import s from './Select.less';

const WalletSelect = () => {
  useStyles(s);
  const { isListSuccess, isListRequest, accounts, selectedAccount } =
    useAccount();
  const { isConnecting, isConnected, isReconnecting } = useWagmiAccount();
  const [show, setShow] = useState(false);
  const { address } = useWallet();
  const [showLinkButton, setShowLinkButton] = useState(false);

  useEffect(() => {
    const isWelcomeScreen =
      window.location.pathname === '/accounts' &&
      (isListRequest || isListSuccess) &&
      !accounts.length;
    const isProfilesScreen = window.location.pathname === '/profiles';
    const isDevelopersScreen = window.location.pathname === '/developers';
    const isConfirmScreen = window.location.pathname === '/confirm';
    const isAutoSelect = window.location.pathname === '/';
    const isSupport = window.location.pathname === '/help';
    const isInvite = window.location.pathname === '/invitecodes';
    const isNoble =
      window.location.href.includes('noble') ||
      (selectedAccount?.address?.includes('noble') &&
        window.location.href.includes('/accounts/'));

    const hide =
      isNoble ||
      isAutoSelect ||
      isConfirmScreen ||
      isWelcomeScreen ||
      isProfilesScreen ||
      isDevelopersScreen ||
      isSupport ||
      isInvite;

    setShow(!hide);
  }, [isListRequest, isListSuccess, accounts, window.location.pathname]);

  useEffect(() => {
    const isLinkScreen = window.location.pathname.includes('/addresses/link');
    const isPartnerScreen = window.location.pathname.includes('/partner');
    if (
      accounts?.length > 0 &&
      !accounts.find((a) => equal(a.address, address)) &&
      !isLinkScreen &&
      !isPartnerScreen &&
      isConnected
    ) {
      setShowLinkButton(true);
    } else {
      setShowLinkButton(false);
    }
  }, [accounts, address, isConnected]);

  if (isConnecting || isReconnecting) {
    return <div style={{ height: '40px' }} />;
  }

  return show ? (
    <div id="emoney-wallet-select-btn" className={s.row}>
      {showLinkButton && (
        <div className={s.linkBtnWrapper}>
          <Button
            small
            outlined
            icon="priority_high"
            href={`/addresses/link/${address}`}
            to={`/addresses/link/${address}`}
          >
            Link your wallet
          </Button>
        </div>
      )}
      <ConnectButton
        label="Connect wallet"
        accountStatus={{
          smallScreen: 'address',
          largeScreen: 'address',
        }}
        chainStatus="icon"
        showBalance={{ smallScreen: false, largeScreen: false }}
      />
    </div>
  ) : null;
};

export default WalletSelect;
