// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xcyfC{-ms-flex-align:center;align-items:center;-ms-flex-direction:column;flex-direction:column;max-width:500px;text-align:center}.ANR2H,.xcyfC{display:-ms-flexbox;display:flex}.ANR2H{-ms-flex-pack:center;justify-content:center}.ANR2H,.ZOzL1{margin-bottom:15px}.ZOzL1{font-weight:900;text-align:center}.NrF6o{background-color:var(--danger-background-color);border-radius:var(--border-radius);color:var(--danger-color);padding:5px}@media (max-width:574px){.NrF6o{display:block}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "xcyfC",
	"icon": "ANR2H",
	"title": "ZOzL1",
	"failure": "NrF6o"
};
module.exports = ___CSS_LOADER_EXPORT___;
