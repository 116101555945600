// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gMyUt{margin-bottom:30px;position:relative;width:100%}.kPQ4B{color:#585e68;font-size:10px;font-size:.625rem;margin-top:15px}.rpIKK{border-radius:3px;color:var(--danger-background-color);line-height:1.5;margin:5px 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "gMyUt",
	"info": "kPQ4B",
	"errorMessage": "rpIKK"
};
module.exports = ___CSS_LOADER_EXPORT___;
